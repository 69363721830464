import { Container, Image } from 'react-bootstrap';
import './index.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { cardBrands } from '../../assets/images';
import { WEBSITE_NAME } from '../../config';

function Footer() {
    return (
        <section className='footer'>
            <Container>
                <Row>
                    <Col md={4} className='copyright'>
                        <p>
                        © 2024 {WEBSITE_NAME}
                        </p>
                    </Col>
                    <Col md={4} className='footer-image'>
                        <Image src={cardBrands} alt='' fluid />
                    </Col>
                    <Col md={4} className='terms-privacy'>
                        <a className='terms' href='https://www.pearsonbookpublisher.com/terms-and-conditions'>
                        Terms of Services
                        </a>
                        <a className='privacy' href='https://www.pearsonbookpublisher.com/privacy-policy'>
                            Privacy Policy
                        </a>
                    </Col>
                </Row>
            </Container>
        </section>

    );
}

export default Footer;