import './index.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { firstDraft, limitlessRevisions, orderAHatch, tedy, theFinalTakeoff, theRevision } from '../../assets/images';

function Process() {
    return (
        <section className='process'>
            <Container>
                <p className='process-heading text-center'>
                    How the Children’s Book Illustration Cycle Works
                </p>
                <p className='process-text text-center'>
                    Creating captivating illustrations for children’s books is a collaborative journey, and our process ensures that your vision comes to life every step of the way.
                </p>
                <Row className='process-steps'>
                    <Col md={4}>
                        <img src={orderAHatch} alt='' className='img-fluid' />
                        <p className='offers-box-heading'>
                            Order a Hatch
                        </p>
                        <p className='offers-box-text'>
                            Start by sharing your ideas and details about your book through our easy order form. Not sure what you want for the cover? Don’t worry! Our team is here to help guide you and bring your concept to fruition.
                        </p>
                    </Col>
                    <Col md={4}>
                        <Image className="tedy" src={tedy} alt="" fluid />
                    </Col>
                    <Col md={4}>
                        <img src={theFinalTakeoff} alt='' className='img-fluid' />
                        <p className='offers-box-heading'>
                            The Final Takeoff
                        </p>
                        <p className='offers-box-text'>
                            Once you’re thrilled with the design, we prepare the print-ready files. You’ll receive high-quality digital files, ready for publishing. Your story is now beautifully illustrated and ready to inspire!
                        </p>
                    </Col>
                    <Col md={4}>
                        <img src={firstDraft} alt='' className='img-fluid' />
                        <p className='offers-box-heading'>
                            Sketch and Feedback
                        </p>
                        <p className='offers-box-text'>
                            Once we have your ideas, our illustrators will create initial sketches. You’ll have the opportunity to provide feedback, ensuring the illustrations align perfectly with your vision. This stage allows for creative input and collaboration.
                        </p>
                    </Col>
                    <Col md={4}>
                        <img src={theRevision} alt='' className='img-fluid' />
                        <p className='offers-box-heading'>
                            Final Design
                        </p>
                        <p className='offers-box-text'>
                            After incorporating your feedback, our team will develop the final illustrations. We focus on every detail, ensuring that the artwork captures the essence of your story and engages young readers.
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Process;
