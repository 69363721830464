import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import './index.css';
import { loader } from '../../assets/images';

export const ModalSection = ({ open, onCloseModal }) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '', // Added message field
    });

    // Handle input changes to update form data
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission
    const onSubmitFunc = async (e) => {
        e.preventDefault();

        console.log('formData', formData);

        // Phone number validation (at least 9 characters)
        if (formData.phone.length < 9) {
            setErrors(true);
        } else {
            setErrors(false); // Reset errors

            const currentUrl = window.location.href;
            const protocol = window.location.protocol; // "https:"
            const hostname = window.location.hostname; // "creativeghostwriters.org"

            // Construct the base URL
            const baseUrl = `${protocol}//${hostname}`;
            const queryStringFormData = new URLSearchParams(formData).toString();

            let finalReq = {
                ...formData,
                source: `${currentUrl}${queryStringFormData}`,
                domain: baseUrl,
                lead_url: currentUrl,
                url: `${currentUrl}${queryStringFormData}`,
            };

            try {
                setLoading(true); // Start loading
                const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=337576';
                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                };

                const formDataString = new URLSearchParams(finalReq).toString();

                const response = await axios.post(apiUrl, formDataString, config);

                if (response.status === 200) {
                    const queryString = new URLSearchParams(response.data.data).toString();
                    console.log('queryString', queryString);
                    setLoading(false); // Stop loading
                    setFormData({ name: '', email: '', phone: '', message: '' }); // Reset form
                    window.location.href = `https://www.pearsonbookpublisher.com/thankyou?${queryString}`;
                }
            } catch (error) {
                console.error('Error:', error);
                setLoading(false); // Stop loading
            }
        }
    };

    return (
        <Modal
            id="exampleModal"
            show={open}
            onHide={onCloseModal}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <button className="btn-close" onClick={onCloseModal}></button>
                <div className="content_overlay">
                    <div className="popup_content">
                        <h2>
                            ACTIVATE YOUR BOOK PUBLISHING COMPANY COUPON TO AVAIL UPTO 50% DISCOUNT!
                            <br /><br />
                            <span>LAST 7 COUPONS LEFT</span>
                        </h2>
                        <form className="main-pop form_submission" onSubmit={onSubmitFunc}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Full Name"
                                required
                                value={formData.name}
                                onChange={handleInputChange} // Linked to state
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Enter Email"
                                required
                                value={formData.email}
                                onChange={handleInputChange} // Linked to state
                            />
                            <input
                                type="number"
                                name="phone"
                                maxLength="12"
                                minLength="10"
                                placeholder="Enter phone"
                                required
                                value={formData.phone}
                                onChange={handleInputChange} // Linked to state
                            />
                            <textarea
                                name="message"
                                placeholder="Enter a brief description about your book"
                                value={formData.message}
                                onChange={handleInputChange} // Linked to state
                            />
                            {errors && <p style={{ color: 'red' }}>Phone number must be at least 9 characters long.</p>}
                            {loading == true ? (
                                <div class="loader">
                                    <img alt="loader" src={loader} />
                                </div>
                            ) : (
                                <input type="submit" value="Claim Discount" />
                            )}
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
