import './index.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { WEBSITE_NAME } from '../../config';

function Testimonials() {
    return (
        <section className='testimonials'>
            <Container>
                <Col md={12}>
                    <p className='testimonials-heading'>
                    What Our Clients Are Saying
                    </p>
                    <p className='testimonials-text'>
                    Discover how Pearson Book Publisher has made a difference for authors and illustrators. Read their experiences and see why they trust us to bring their stories to life!
                    </p>
                </Col>
                <Row className='testimonials-row'>
                    <Col lg={5}>
                        <div class="cir">
                            <h3>RW</h3>
                        </div>
                        <div class="wrap">
                            <h6 class="contentscroll">"Pearson Book Publisher transformed my vision into reality! Their team guided me through the publishing journey, making it seamless and enjoyable. I’m thrilled with the final product and the beautiful illustrations!"</h6>
                            <h5>Rhys Wong<span>Chairman - CEO</span></h5>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <div class="cir">
                            <h3>EF</h3>
                        </div>
                        <div class="wrap">
                            <h6 class="contentscroll">"Working with Pearson Book Publisher was a fantastic experience. Their creativity and attention to detail brought my story to life in ways I never imagined. I highly recommend their services to anyone looking to publish!"</h6>
                            <h5>Eric Flores<span>Author</span></h5>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <div class="cir">
                            <h3>AB</h3>
                        </div>
                        <div class="wrap">
                            <h6 class="contentscroll">"Collaborating with Pearson Book Publisher was a breath of fresh air. Their commitment to quality and professionalism stood out. I’m proud to showcase my book, and I couldn’t have done it without their help!"</h6>
                            <h5>Alex Bishop<span>Educationist and Author</span></h5>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <div class="cir">
                            <h3>CS</h3>
                        </div>
                        <div class="wrap">
                            <h6 class="contentscroll">"As a new author, I was nervous about the publishing process. Pearson Book Publisher provided me with the support and knowledge I needed to feel confident. Their expertise truly made a difference!"</h6>
                            <h5>Christopher Solomon<span>Author</span></h5>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Testimonials;
