import { img02, img03, img2, img4jpg, img8, img81 } from '../../assets/images';
import { PHONE, PHONE_HREF } from '../../config';
import './index.css';

function Services({ onOpenModal }) {
    return (
        <section className='services'>
            <div className='container'>
                <p className='services-heading'>
                    Our Incredible Collection of Children’s Books
                </p>
                <p className='services-text'>
                    Explore a selection of our finest works, where imagination meets artistry, and every page invites adventure.
                </p>
                <div className='row'>
                    <div className='col-lg-4 col-md-6'>
                        <div class="our-service">
                            <h2>book cover Illustration</h2>
                            <img src={img02} class="lazy img-fuild" />
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div class="our-service">
                            <h2>book cover Illustration</h2>
                            <img src={img8} class="lazy img-fuild" />
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div class="our-service">
                            <h2>book cover Illustration</h2>
                            <img src={img4jpg} class="lazy img-fuild" />
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div class="our-service">
                            <h2>book cover Illustration</h2>
                            <img src={img2} class="lazy img-fuild" />
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div class="our-service">
                            <h2>book cover Illustration</h2>
                            <img src={img81} class="lazy img-fuild" />
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div class="our-service">
                            <h2>book cover Illustration</h2>
                            <img src={img03} class="lazy img-fuild" />
                        </div>
                    </div>
                </div>
                <div className='services-btn'>
                    <a className='modal-btn' href='javascript:;' onClick={onOpenModal}>Get Started</a>
                    <a className='phn-btn' href={PHONE_HREF}>{PHONE}</a>
                </div>
            </div>
        </section>
    );
}

export default Services;
