import { cta2img } from '../../assets/images';
import { PHONE, PHONE_HREF } from '../../config';
import './index.css';

function CatchUpCTA({ onOpenModal }) {
    return (
        <section className='catchup'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6 catchup-img'>
                        <img src={cta2img} alt='' className='img-fluid' />
                    </div>
                    <div className='col-md-6'>
                        <p className='catchup-heading'>
                            Let’s Connect Today!
                        </p>
                        <p className='catchup-text'>
                            Got great ideas? Our talented writers are ready to bring them to life!
                        </p>
                        <div className='catchup-btn'>
                            <a className='modal-btn' href='javascript:;' onClick={onOpenModal}>Get Started</a>
                            <a className='phn-btn' href={PHONE_HREF}>{PHONE}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CatchUpCTA;
