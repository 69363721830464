const Admin_Panel = 'info@pearsonbookpublisher.com';
const PHONE = '(855) 282-3880';
const PHONE_HREF = 'tel:8552823880';
const EMAIL = 'info@pearsonbookpublisher.com';
const EMAIL_HREF = 'mailto:info@pearsonbookpublisher.com';
const CONTACT_EMAIL = 'info@pearsonbookpublisher.com';
const CONTACT_EMAIL_HREF = 'mailto:info@pearsonbookpublisher.com';
const ADDRESS = '465 Pine St, San Francisco, CA 94104, USA';
const WEBSITE_NAME = 'Pearson Book Publisher';
const SITE_CURRENCY_SYMBOLS = '$';

export {
  Admin_Panel,
  PHONE,
  PHONE_HREF,
  EMAIL,
  EMAIL_HREF,
  CONTACT_EMAIL,
  CONTACT_EMAIL_HREF,
  ADDRESS,
  WEBSITE_NAME,
  SITE_CURRENCY_SYMBOLS,
};