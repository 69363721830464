import { ctaimg } from '../../assets/images';
import { PHONE, PHONE_HREF } from '../../config';
import './index.css';

function CtaOne({ onOpenModal }) {
    return (
        <section className='cta-one'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 col-lg-8'>
                        <p className='cta-one-heading'>
                            Let’s Make Your Story Shine!
                        </p>
                        <p className='cta-one-text'>
                            Our illustrators can’t wait to bring your vision to life! Share your ideas with us today, and let’s create something special together.
                        </p>
                        <div className='cta-one-btn'>
                            <a className='modal-btn' href='javascript:;' onClick={onOpenModal}>Get Started</a>
                            <a className='phn-btn' href={PHONE_HREF}>{PHONE}</a>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <img src={ctaimg} alt='' />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CtaOne;
