import './index.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

function LastCTA({onOpenModal}) {
    return (
        <section className='last-cta'>
            <Container>
                <Col md={12}>
                    <p className='last-cta-heading'>
                        Our Illustration Masterminds Are Ready To Work On Your Children’s Book
                    </p>
                    <p className='last-cta-text'>
                        Don’t Miss Out On The Opportunity; Get Your Fantastic Illustrations Today!
                    </p>
                    <a href='javascript:;' className='modal-btn' onClick={onOpenModal}>Get Started</a>
                </Col>
            </Container>
        </section>
    );
}

export default LastCTA;
