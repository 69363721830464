import React, { useState } from 'react';
import axios from 'axios';
import { bannerForm, loader } from '../../assets/images';
import { PHONE, PHONE_HREF } from '../../config';
import './index.css';

function Banner({ onOpenModal }) {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        phone: '',
    });

    const onSubmitFunc = async (e) => {
        e.preventDefault();

        console.log('formData', formData);
        if (formData.phone.length < 9) {
            setErrors(true);
        } else {
            const currentUrl = window.location.href;
            const protocol = window.location.protocol; // "https:"
            const hostname = window.location.hostname; // "creativeghostwriters.org"

            // Construct the base URL
            const baseUrl = `${protocol}//${hostname}`;
            const queryStringFormData = new URLSearchParams(formData).toString();

            let finalReq = {
                ...formData,
                source: `${currentUrl}${queryStringFormData}`,
                domain: baseUrl,
                lead_url: currentUrl,
                url: `${currentUrl}${queryStringFormData}`,
            };
            try {
                setLoading(true);
                const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=337576';
                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                };

                const formDataString = new URLSearchParams(finalReq).toString();

                const response = await axios.post(apiUrl, formDataString, config);
                if (response.status == 200) {
                    // toast.success("Thank you for filling out the information");
                    // console.log(response.data.data.id);
                    const queryString = new URLSearchParams(
                        response.data.data
                    ).toString();
                    console.log('queryString', queryString);
                    setLoading(false);
                    setErrors(false);
                    setFormData({
                        name: '',
                        email: '',
                        message: '',
                        phone: '',
                    });
                    // naviagte(`/thank-you?${queryString}`)
                    window.location.href = `https://www.pearsonbookpublisher.com/thankyou?${queryString}`;
                }
                console.log('responseresponseresponse', response);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };
    return (
        <section className='banner'>
            <div className='container'>
                <div className='row'>
                    <div className='col-xl-5 col-lg-6 col-md-12'>
                        <p className='banner-heading'>
                            Publish Your Children's Book Today!
                        </p>
                        <p className='banner-text'>
                        Transform your magical stories into beautifully crafted books that inspire young minds. Join us at Pearson Book Publisher and we’ll help you every step of the way on your publishing journey!
                        </p>
                        <div className='banner-btn'>
                            <a href='javascript:;' className='modal-btn' onClick={onOpenModal}>Get Started</a>
                            <a href={PHONE_HREF} className='phn-btn'>{PHONE}</a>
                        </div>
                    </div>
                    <div className='col-xl-4 col-lg-6 col-md-12 banner-form'>
                        <p className='banner-form-text col-lg-8'>
                            Limited Time OFFER <span>50%</span> OFF
                        </p>
                        <form method='POST' onSubmit={onSubmitFunc}>
                            <input type='text' name='name' className='form-control' placeholder='Enter Your Name' required onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                            <input type='email' name='email' className='form-control' placeholder='Enter Your Email' required onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                            <input type='number' name='phone' className='form-control' placeholder='Enter Your Number' required onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                            <input type='text' name='message' className='form-control' placeholder='Enter Your Message' required onChange={(e) => setFormData({ ...formData, message: e.target.value })} />
                            {loading == true ? (
                                <div class="loader">
                                    <img alt="loader" src={loader} />
                                </div>
                            ) : (
                                <button className='btn submit-btn'>Submit</button>
                            )}
                        </form>
                    </div>
                    <div className='col-lg-3 banner-form-image'>
                        <img src={bannerForm} alt='' className='img-fluid' />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner;
