import { Container } from 'react-bootstrap';
import React, { useState } from 'react';
import axios from 'axios';
import './index.css';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { loader } from '../../assets/images';

function FooterForm() {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        phone: '',
        service: '',  // Added service field
        budget: '',   // Added budget field
    });

    const onSubmitFunc = async (e) => {
        e.preventDefault();

        console.log('formData', formData);
        if (formData.phone.length < 9) {
            setErrors(true);
        } else {
            const currentUrl = window.location.href;
            const protocol = window.location.protocol;
            const hostname = window.location.hostname;

            const baseUrl = `${protocol}//${hostname}`;
            const queryStringFormData = new URLSearchParams(formData).toString();

            let finalReq = {
                ...formData,
                source: `${currentUrl}${queryStringFormData}`,
                domain: baseUrl,
                lead_url: currentUrl,
                url: `${currentUrl}${queryStringFormData}`,
            };
            try {
                setLoading(true);
                const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=337576';
                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                };

                const formDataString = new URLSearchParams(finalReq).toString();

                const response = await axios.post(apiUrl, formDataString, config);
                if (response.status === 200) {
                    const queryString = new URLSearchParams(
                        response.data.data
                    ).toString();
                    console.log('queryString', queryString);
                    setLoading(false);
                    setErrors(false);
                    setFormData({
                        name: '',
                        email: '',
                        message: '',
                        phone: '',
                        service: '',
                        budget: '',
                    });
                    window.location.href = `https://www.pearsonbookpublisher.com/thankyou?${queryString}`;
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    return (
        <section className='footer-form'>
            <Container>
                <p className='footer-form-heading'>
                    Let’s Get Started
                </p>
                <p className='footer-form-text'>
                    Call (Toll Free) (844) 237-2738 or use the form below to start a conversation and show you how we can help!
                </p>
                <Form onSubmit={onSubmitFunc}>
                    <Row className="mb-4">
                        <Form.Group as={Col}>
                            <Form.Control
                                type="text"
                                placeholder="Enter Your Name"
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                value={formData.name}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Control
                                type="email"
                                placeholder="Enter Your Email"
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                value={formData.email}
                            />
                        </Form.Group>
                    </Row>
                    <Row className='mb-4'>
                        <Form.Group as={Col} controlId="formGridService">
                            <Form.Select
                                value={formData.service}
                                onChange={(e) => setFormData({ ...formData, service: e.target.value })}
                            >
                                <option value="" disabled>Select Your Service</option>
                                <option value="Ghost Writing">Ghost Writing</option>
                                <option value="Editing">Editing</option>
                                <option value="Publishing">Publishing</option>
                                <option value="Proofreading">Proofreading</option>
                                <option value="Audio Book">Audio Book</option>
                                <option value="Children Book">Children Book</option>
                                <option value="Book Cover">Book Cover</option>
                                <option value="Book Promotion">Book Promotion</option>
                                <option value="Formatting">Formatting</option>
                                <option value="e-Book Writing">e-Book Writing</option>
                                <option value="Blog Writing">Blog Writing</option>
                                <option value="Website Content Writing">Website Content Writing</option>
                                <option value="Article Writing">Article Writing</option>
                                <option value="Book Video Trailer">Book Video Trailer</option>
                                <option value="Author Website">Author Website</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridBudget">
                            <Form.Select
                                value={formData.budget}
                                onChange={(e) => setFormData({ ...formData, budget: e.target.value })}
                            >
                                <option value="" disabled>Select Your Budget</option>
                                <option value="Below 2000">Below 2000</option>
                                <option value="Upto 3000">Upto 3000</option>
                                <option value="Upto 4000">Upto 4000</option>
                                <option value="Upto 5000">Upto 5000</option>
                                <option value="Upto 6000">Upto 6000</option>
                                <option value="Upto 7000">Upto 7000</option>
                                <option value="Upto 8000">Upto 8000</option>
                                <option value="Upto 9000">Upto 9000</option>
                                <option value="Upto 10000">Upto 10000</option>
                                <option value="Above 10000">Above 10000</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-4">
                        <Form.Control
                            type="number"
                            placeholder="Enter Your Phone Number"
                            onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                            value={formData.phone}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control
                            className="message"
                            placeholder="Enter Your Message"
                            onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                            value={formData.message}
                        />
                    </Form.Group>
                    {loading == true ? (
                        <div class="loader">
                            <img alt="loader" src={loader} />
                        </div>
                    ) : (
                        <input type="submit" value="Submit" class="submit"/>
                    )}
                </Form>
            </Container>
        </section>
    );
}

export default FooterForm;
