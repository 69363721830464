import { aCreativePunch, limitlessRevisions, lowCostServices, multipleLayouts, onTimeDeliveries, ownershipRights } from '../../assets/images';
import Container from 'react-bootstrap/Container';
import './index.css';

function Offers() {
    return (
        <section className='offers'>
            <Container>
                <p className='offers-heading'>
                    Why Choose Us for Your Children’s Book Publication?
                </p>
                <p className='offers-text'>
                    When it comes to illustrating your children’s book, our team is committed to bringing your vision to life with exceptional artistry. Here’s why you can trust us:
                </p>
                <div className='row'>
                    <div className='col-lg-3 col-md-6 offers-box'>
                        <img src={limitlessRevisions} alt='' className='img-fluid' />
                        <p className='offers-box-heading'>
                            Unlimited Revisions
                        </p>
                        <p className='offers-box-text'>
                            We’re dedicated to perfecting your illustrations until you’re satisfied with the final result.
                        </p>
                    </div>
                    <div className='col-lg-3 col-md-6 offers-box'>
                        <img src={lowCostServices} alt='' className='img-fluid' />
                        <p className='offers-box-heading'>
                            Affordable Packages
                        </p>
                        <p className='offers-box-text'>
                            Our services are designed to meet various needs without compromising on quality.
                        </p>
                    </div>
                    <div className='col-lg-3 col-md-6 offers-box'>
                        <img src={aCreativePunch} alt='' className='img-fluid' />
                        <p className='offers-box-heading'>
                            Innovative Creativity
                        </p>
                        <p className='offers-box-text'>
                            Our team is eager to bring fresh and creative ideas to your project, ensuring your illustrations captivate and engage young readers.
                        </p>
                    </div>
                    <div className='col-lg-3 col-md-6 offers-box'>
                        <img src={onTimeDeliveries} alt='' className='img-fluid' />
                        <p className='offers-box-heading'>
                            Timely Delivery
                        </p>
                        <p className='offers-box-text'>
                            Our promise of on-time delivery guarantees that you will receive your illustrations as scheduled, without any hold-ups.
                        </p>
                    </div>
                    <div className='col-lg-3 col-md-6 offers-box'>
                        <img src={multipleLayouts} alt='' className='img-fluid' />
                        <p className='offers-box-heading'>
                            Variety of Layouts
                        </p>
                        <p className='offers-box-text'>
                            Our skilled designers will assist you in choosing the best style for your book.
                        </p>
                    </div>
                    <div className='col-lg-3 col-md-6 offers-box'>
                        <img src={ownershipRights} alt='' className='img-fluid' />
                        <p className='offers-box-heading'>
                            Full Ownership Rights
                        </p>
                        <p className='offers-box-text'>
                            Upon completion of your project, you will own all ownership rights
                        </p>
                    </div>
                </div>
            </Container>
        </section>
    );
}

export default Offers;
