import React, {useState, useEffect} from 'react';
import './index.css';
import { logoPng } from '../../assets/images';
import { PHONE, PHONE_HREF } from '../../config';

function Header({ onOpenModal }) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        // Function to open chat window
        const openChatWindow = () => {
          if (window.$zopim) {
            window.$zopim.livechat.window.show();
          }
        };
    
        // Function to toggle chat window
        const toggleChat = () => {
          if (window.$zopim) {
            window.$zopim.livechat.window.toggle();
          }
        };
    
        // Initialize chat and open by default
        const initializeChat = () => {
          const intervalId = setInterval(() => {
            if (window.$zopim && window.$zopim.livechat) {
              clearInterval(intervalId);
              openChatWindow();
              listenForNewMessages();
            }
          }, 100); // Check every 100ms if chat widget is available
        };
    
        // Listen for new messages
        const listenForNewMessages = () => {
          if (window.$zopim && window.$zopim.livechat) {
            window.$zopim.livechat.setOnUnreadMsgs((numberOfUnreadMessages) => {
              if (numberOfUnreadMessages > 0) {
                openChatWindow();
              }
            });
          }
        };
    
        // let arrayList =["a", "b"];
        // if(arrayList){
        //     return;
        // }else{
            
        // }

        initializeChat();
    
        const chatButtons = document.querySelectorAll('.chat'); // Select all buttons with the class 'chat'
    
        chatButtons.forEach(button => {
          button.addEventListener('click', toggleChat); // Add click event to each button
        });
    
        // Cleanup: Remove event listeners when the component unmounts
        return () => {
          chatButtons.forEach(button => {
            button.removeEventListener('click', toggleChat); // Remove click event from each button
          });
        };
      }, []);
  return (
      <header>
        <div className='container'>
            <div className='row'>
                <div className='col-md-4 logo-col'>
                    <img src={logoPng} alt='' className='img-fluid'/>
                </div>
                <div className='col-md-8 button-col'>
                    <a href={PHONE_HREF} className='phn-btn'>{PHONE}</a>
                    <a href='javascript:;' className='modal-btn' onClick={onOpenModal}>Get a Quote</a>
                    <a href='' className='chat-btn chat'>Live Chat</a>
                </div>
            </div>
        </div>
      </header>
  );
}

export default Header;
 