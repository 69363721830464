import { audienceOrientedContent, deviceCompatibility, easyToUpload, img4png, increasedSales } from '../../assets/images';
import './index.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';

function About() {
    return (
        <section className='about'>
            <Container>
                <Row>
                    <Col xl={5} lg={6} className='about-one'>
                        <p className='about-heading'>
                            Our Illustrators Bring Stories to Life!
                        </p>
                        <p className='about-text'>
                        Our talented illustrators create bright, engaging images that help children connect with your story. They know what excites young readers and make each page fun and inviting. Imagine a world where colors dance, characters leap off the page, and stories come alive! Our creative team specializes in crafting illustrations that resonate with children’s imaginations, turning every page into a vibrant experience. Let us turn your ideas into beautiful illustrations that inspire a love for reading!
                        </p>
                    </Col>
                    <Col md={6}>
                        <Image src={img4png} alt='' fluid />
                    </Col>
                </Row>
                <Row className='btn-row'>
                    <Col lg={3} md={4}>
                        <Image src={increasedSales} alt='' fluid />
                        <button className='btn about-btn'>Increased Sales</button>
                    </Col>
                    <div className='col-lg-3 col-md-4'>
                        <Image src={audienceOrientedContent} alt='' fluid />
                        <button className='btn about-btn'>Audience Oriented Content</button>
                    </div>
                    <div className='col-lg-3 col-md-4'>
                        <Image src={deviceCompatibility} alt='' fluid />
                        <button className='btn about-btn'>Device Compatibility</button>
                    </div>
                    <div className='col-lg-3 col-md-4'>
                        <Image src={easyToUpload} alt='' fluid />
                        <button className='btn about-btn'>Easy To Upload</button>
                    </div>
                </Row>
            </Container>
        </section>
    );
}

export default About;
